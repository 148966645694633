
import Base64 from "base-64";

class BcmData {
    static isLogin(){
        const token = this.getToken();
        return token !== null && token.length > 10;
    }
    static getToken(){
        return localStorage.getItem('token');
    }
    static setToken(token){
        if(!token || token.length <= 0){
            localStorage.removeItem('token')
        }else {
            localStorage.setItem('token', token);
        }
    }

    static getUserNickLocal(){
        let user = this.getUserInfoLocal();
        if(user != null && user.length > 10){
            try {
                return JSON.parse(user).nick;
            }catch (e) {
                return null;
            }
        }
        return null;
    }
    static setUserInfoLocal(user_info){
        localStorage.setItem('user_info', Base64.encode( user_info ));
    }
    static getUserInfoLocal(){
        const str = localStorage.getItem('user_info');
        if(str){
            return Base64.decode(str);
        }
        return null;
    }
}

export {
    BcmData
}