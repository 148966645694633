import BkBasePage from "../../base/BkBasePage";
import React from 'react';
import {Button, message, Tabs, Spin} from 'antd';
import {BcmFetch} from "../../data/BcmFetch";
import BcmLogger from "../../base/BcmLogger";
import MsHotList from "./MsHotList";
const { TabPane } = Tabs;



class MsHotsPage extends BkBasePage {

    constructor(props){
        super(props);
        this.state = {
        	loading_status: 1,
        	cate_panes : [
		      { title: 'Loading', time: '00:00', key: '1' },]
        }
    }

	componentDidMount() {
		super.componentDidMount();
		this.queryLimitTimeCate();
	}

	render() {
		const isLoading = this.state.loading_status === 0;
    	const cate_panes = this.state.cate_panes;
		return <div>
					<div style={{display: 'flex', justifyContent: 'space-between', margin: '10px'}}>
						<div> </div>
						<Button onClick={() => this.onClickRefresh()}
						        loading={isLoading}>刷新</Button>
					</div>
					<Spin spinning={isLoading}>
						<Tabs defaultActiveKey="1">
							{cate_panes.map(pane => (
								<TabPane tab={pane.title +'('+pane.time+')'} key={pane.key}>
									<MsHotList time_id={pane.key}/>
								</TabPane>
					        ))}
						</Tabs>
					</Spin>
              </div>
	}

    onClickRefresh(){
    	this.setState({ loading_status: 0});
    	this.queryLimitTimeCate();
    }
	queryLimitTimeCate(){
    	const _this = this;
		BcmFetch.bkLimitTimeCate().then((response) => {
			if(response.data.code === 0 && response.data.data){
				//BcmLogger.log('response.data.data :'+ response.data.data);
				_this.setState({
					loading_status: 1,
					cate_panes: response.data.data.map(item => {
						return {
							...item,
							title: item.status,
							key: item.id,
						}
					})
				});
			}else {
				message.error("加载失败");
				_this.setState({loading_status: 2})
			}
		}).catch((error)=>{
			BcmLogger.error('queryLimitTimeCate error :'+ error);
			message.error("加载错误");
			_this.setState({loading_status: -1})
		})
	}
}

export default MsHotsPage;