import BkBasePage from "../../base/BkBasePage";
import React from 'react';
import {Button, message, Form, DatePicker, AutoComplete, Input, Spin, Radio} from 'antd';
import {BcmFetch} from "../../data/BcmFetch";
import BcmLogger from "../../base/BcmLogger";
import moment from "moment";



class AddRecommendDialog extends BkBasePage {

    constructor(props){
        super(props);
        this.state = {
        	loading_status : 1,
	        edit_mode: props.mode === 'edit',
	        shop_type: this.props.item_recommend.goods.shop_type,
        }
    }

	componentDidMount() {
		super.componentDidMount();
	}

	render() {
    	const is_edit_mode = this.state.edit_mode;
		const isLoading = this.state.loading_status === 0;
    	const item_recommend = this.props.item_recommend;
    	const goods = item_recommend.goods;
    	const title = item_recommend ? item_recommend.title : goods? goods.title : '';
    	const disable_id_input = this.props.disable_edit_id || is_edit_mode;
    	const shop_type = this.state.shop_type ? this.state.shop_type : goods.shop_type;
    	if(!goods && !item_recommend){
    		return <div> 错误 </div>
	    }
    	BcmLogger.log("render goods=" + JSON.stringify(goods));
		return <div>
					<Spin spinning={isLoading}>
						<Form onFinish={(values)=>this.clickSubmitItem(values)}
						initialValues={{
							['date_time_picker']: item_recommend && item_recommend.recommend_time ?
								moment(item_recommend.recommend_time, 'YYYY-MM-DD HH:mm:ss')
								: moment(),
							['type_name']: item_recommend? item_recommend.type_name: '',
							['phrase']: item_recommend? item_recommend.phrase : '',
							['recommend_title']: title
						}}>
							<Radio.Group onChange={(e)=>this.onMallChange(e)} value={shop_type} disabled={disable_id_input}>
								<Radio value={'B'}>天猫</Radio>
								<Radio value={'C'}>淘宝</Radio>
								<Radio value={'J'}>京东</Radio>
							</Radio.Group>
							{is_edit_mode ?
								<div>
									<Form.Item label="推荐ID" name="recommend_id">
										<Input defaultValue={item_recommend.id} disabled/>
									</Form.Item>
									<Form.Item label="推荐标题" name="recommend_title">
										<Input  plceholder="推荐标题(选填)"/>
									</Form.Item>
								</div>
								:
								<div>
									<Form.Item label="商品ID" name="item_id">
										<Input defaultValue={goods.item_id} disabled={disable_id_input?'disabled' : ''}/>
									</Form.Item>
									< Form.Item label="券ID" name="quan_id" >
									<Input defaultValue={goods.quan_id} disabled={disable_id_input?'disabled' : ''} />
									</Form.Item>
								</div>
							}
							<Form.Item label="推荐时间" name="date_time_picker"
								rules={[{ required: true, message: '必须要有时间' }]}>
								<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
							</Form.Item>
							<Form.Item label="分类名称" name="type_name"
								rules={[{ required: true, message: '必须要有分类名' }]}>
							    <AutoComplete
								    style={{ width: '70%' }}
								    placeholder="分类的名称"
								    options={[{ value: '爆款🤩' }, { value: '白菜价' }
								            , { value: '日用品' }, { value: '女生专属' }, ]} />
							</Form.Item>
							<Form.Item label="推荐语" name="phrase">
							    <Input
								    style={{ width: '70%' }}
								    placeholder="推荐语(选填)"/>
							</Form.Item>
							<Form.Item>
								<Button type="primary" htmlType="submit">确定</Button>
							</Form.Item>
					      </Form>
					</Spin>
              </div>
	}
	onMallChange(e){
    	this.setState({shop_type: e.target.value})
	}
	clickSubmitItem(fieldsValue){
    	const _this = this;
    	const is_edit_mode = this.state.edit_mode;

    	const shop_type = this.state.shop_type ? this.state.shop_type : 'B';
    	const date_time = fieldsValue['date_time_picker'].format('YYYY-MM-DD HH:mm:ss');
    	const type_name = fieldsValue['type_name'];
    	const phrase = fieldsValue['phrase'];
    	const title = fieldsValue['recommend_title'];
    	const input_item_id = fieldsValue['item_id'];
    	const input_quan_id = fieldsValue['quan_id'];

		BcmLogger.error('date_time:' + date_time + ", type_name:" + type_name + ", phrase:" + phrase);
    	let requestRecommend = null;
    	if(is_edit_mode) {
		    const {id} = this.props.item_recommend;
    	    if(!id || !date_time || !type_name){
				message.warn('参数不足');
	            return;
	        }
    		requestRecommend = BcmFetch.bkEditRecommend(id, type_name, title, phrase, date_time);
	    }else {
    	    let {item_id, quan_id} = this.props.item_recommend.goods;
    	    if(!item_id){
    	    	item_id = input_item_id;
	        }
    	    if(!quan_id){
    	    	quan_id = input_quan_id;
	        }
    	    if(!item_id || !quan_id || !date_time || !type_name){
				message.warn('参数不足');
	            return;
	        }
    		requestRecommend = BcmFetch.bkAddRecommend(shop_type, item_id, quan_id, type_name, phrase, date_time);
	    }

    	//BcmLogger.log(" clickItem item_id=" + item_id + "   quan_id=" + quan_id + "  date_time=" + date_time + " type_name=" + type_name);

    	this.setState({loading_status: 0});
		requestRecommend.then((response) => {
			let msg = (is_edit_mode ? "修改":"添加") + '失败';
			if(response.data.code === 0){
				message.success((is_edit_mode ? "修改":"添加") + '成功');
				if(_this.props.callback) {
					_this.props.callback(true);
				}
				return;
			}
			if (response.data.msg && response.data.msg){
				msg = response.data.msg;
			}
			message.error(msg);
			_this.setState({loading_status: 2})
		}).catch((error)=>{
			BcmLogger.error('bkAddRecommend error :'+ error);
			message.error((is_edit_mode ? "修改":"添加") + "错误");
			_this.setState({loading_status: -1})
		})
	}
}

export default AddRecommendDialog;