import React from 'react';
import BkBasePage from "../base/BkBasePage";
import {BcmFetch} from "../data/BcmFetch";
import BcmLogger from "../base/BcmLogger";
import {Spin} from "antd";
import BkHomePage from "../home/BkHomePage";
import './BkMainPage.css'



class BkMainPage extends BkBasePage {
    constructor(props){
        super(props);
        this.state = {
            login_status: 1
        }
    }
    render() {
        const login_status = this.state.login_status;
        let contentComponent =
            <div className="main-loading">
                <Spin spinning={login_status === 0} tip="加载中" />
            </div>;
        if(login_status === 1){
            contentComponent = <BkHomePage />
        }else if(login_status > 1) {
            contentComponent = <div> 登录错误，请重新登录 </div>
        }else if(login_status < 0){
            contentComponent = <div> 加载登录失败,请<a href="">刷新网页</a> </div>
        }
    	return <div style={{
    	    display: 'flex', justifyContent: 'center', alignItems:'center',
    	    height: '100%', width:'100%'
    	}}> {contentComponent} </div>
    }

    componentDidMount() {
        super.componentDidMount();
        this.meLogin();
    }

    meLogin(){
        BcmFetch.userInfo().then((response)=>{
            //BcmLogger.log(response.data);
            if(response.data.code === 0){
                this.setState({
                    login_status: 1
                })
            }else {
                this.setState({
                    login_status: 2
                });
                this.props.history.push({pathname: '/login'});
            }
            //BcmLogger.log(response.data.code);
        }).catch((error)=>{
            BcmLogger.error(error);
            this.setState({
                login_status: -1
            })
        })
    }
}

export default BkMainPage;