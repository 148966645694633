import axios from "axios";
import Base64 from "base-64";
import {BcmData} from "./BcmData";
import queryString from "query-string";

axios.defaults.withCredentials=true;

const querystring = require('querystring');

const BaseUrl = process.env.REACT_APP_BASE_URL;
class BcmFetch {
    static setUserInfoLocal(user_info){
        if(user_info == null){
            localStorage.removeItem("user_info");
            return;
        }
        localStorage.setItem('user_info', Base64.encode( user_info ));
    }
    static getUserInfoLocal(){
        const str = localStorage.getItem('user_info');
        if(str){
            return Base64.decode(str);
        }
        return null;
    }


    static getAxiosClient() {
        return  axios.create({
            //baseURL: '配置路径',
            //timeout: '3000',  //超时时间
            headers: {'X-Token': BcmData.getToken()}  //请求头
        });
    }
    static userLogin(phone, pwd){
        //let data = { "phone": phone , "pwd": pwd };
        let data = new FormData();
        data.append('phone',phone);
        data.append('pwd',pwd);
        return this.getAxiosClient().post(BaseUrl + '/api/v1/login', data);
    }
    static userInfo() {
        return this.getAxiosClient().get(BaseUrl + '/api/v1/user/show');
    }

    static bkSummary() {
        return this.getAxiosClient().get(BaseUrl + '/api/bk/v1/summary');
    }
    static bkAnLive(page=1, pageSize=10, filters) {
        return this.getAxiosClient().get(BaseUrl + '/api/bk/v1/anlive?'
	        + querystring.stringify({
		        page: page, page_size: pageSize, filters: JSON.stringify(filters)
	        }));
    }
    static bkExOrderList(page=1, pageSize=10, filters) {
        return this.getAxiosClient().get(BaseUrl + '/api/bk/v1/ex_order/list?'
	        + querystring.stringify({
		        page: page, page_size: pageSize, filters: JSON.stringify(filters)
	        }));
    }
    static bkExOrderSync(year=2020, month=0) {
        return this.getAxiosClient().get(BaseUrl + '/api/bk/v1/ex_order/sync?'
	        + querystring.stringify({
		        year: year, month: month
	        }));
    }
    static bkLimitTimeCate() {
        return this.getAxiosClient().get(BaseUrl + '/api/bk/v1/recommend/limit_time_cate');
    }
    static bkLimitTimeList(time_id=1, page=1) {
        return this.getAxiosClient().get(BaseUrl + '/api/bk/v1/recommend/limit_time_list?'+ querystring.stringify({
		        time_id: time_id, page: page
	        }));
    }
    static bkAddRecommend(mall='tb', time_id, quan_id, type_name, phrase, date_time) {
        return this.getAxiosClient().get(BaseUrl + '/api/bk/v1/recommend/add_recommend?'+ querystring.stringify({
	            mall: mall,
		        item_id: time_id, quan_id: quan_id,
	            type_name:type_name, phrase: phrase,
	            date_time: date_time,
	        }));
    }
    static bkEditRecommend(recommend_id, type_name, title, phrase, date_time) {
        return this.getAxiosClient().get(BaseUrl + '/api/bk/v1/recommend/edit_recommend?'+ querystring.stringify({
		        recommend_id: recommend_id,
	            type_name:type_name,
	            date_time: date_time,
	            title: title,
	            phrase: phrase,
	        }));
    }
    static frRecommendToday(page=1, page_size=20) {
        return this.getAxiosClient().get(BaseUrl + '/api/v1/recommend/today?'+ querystring.stringify({
		        page_size: page_size, page: page
	        }));
    }
    static frRecommendBefore(page=1, page_size=20) {
        return this.getAxiosClient().get(BaseUrl + '/api/v1/recommend/before?'+ querystring.stringify({
		        page_size: page_size, page: page
	        }));
    }




    static searchNet(keyword, orderBy='auto', quanOnly=false, page = 1, mall = 'tb') {

    	if(page === 1 && orderBy==='auto' && quanOnly===false) {
    		// 避免太多重复数据
		    BcmFetch.analysis_click('search', queryString.stringify({mall: mall, q: keyword}));
	    }

        return this.getAxiosClient().get(BaseUrl + '/api/v1/search/list?' + this.obtParams({
		    q: keyword,
			page: page,
		    order_by: orderBy,
		    quan_only: (quanOnly ? 1 : 0),
		    mall: mall
	    }));
    }
    static getDetail(mall='tb', item_id, show = 0, quan_id = ''){
    	// console.log( 'url= ' + '/api/v1/goods/item?' + this.obtParams({
	    //     item_id: item_id,
	    //     mall: mall,
	    //     show: show,
	    //     quan_id: encodeURIComponent(quan_id)
		//
        // }));
        return this.getAxiosClient().get(BaseUrl + '/api/v1/goods/item?' + this.obtParams({
	        item_id: item_id,
	        mall: mall,
	        show: show,
	        quan_id: encodeURIComponent(quan_id)

        }))
    }
    static analysis_view(path, params){
        let data = { "t": 1, "p": path , "s": params };
        return this.getAxiosClient().post(BaseUrl + '/api/v1/analysis', data);
    }
    static analysis_click(name, params){
        let data = { "t": 2, "n": name , "s": params };
        return this.getAxiosClient().post(BaseUrl + '/api/v1/analysis', data);
    }

    static obtParams(params){
    	let str = '';
		Object.getOwnPropertyNames(params).forEach(function(key){
            str = str + key + "=" + params[key] + "&"
        });
		if(str.endsWith("&")){
			str = str.substr(0, str.length - 1)
		}
	    return str;
    }
}

export {
    BcmFetch
}