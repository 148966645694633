import React from 'react';
import { Form, Input, Button, message} from 'antd';
import Text from "antd/lib/typography/Text";
import BkBasePage from "../base/BkBasePage";
import {BcmFetch} from "../data/BcmFetch";
import {BcmData} from "../data/BcmData";
import './BkLoginPage.css'



const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 12 },
};
class BkLoginPage extends BkBasePage {
    constructor(props){
        super(props);
        this.state = {
            loginLoading: false
        }
    }
    render() {
    	return <div style={{backgroundColor:'white', width: '100%', height: '100%'}}>
            <div className="Login-layout-main">
                <div className="Login-layout-content">
                    <Text className="Login-logo">拔草猫管理后台</Text>
                    <div style={{display:"flex", width: '60%', padding: '0, 60px', justifyContent: 'center', alignItems: 'center'}}>
                        <Form style={{width: '100%'}}
                            {...layout}
                            name="basic"
                            onFinish={this.onFinish.bind(this)}
                            onFinishFailed={this.onFinishFailed}>
                            <Form.Item
                                label="手机号"
                                name="phone"
                                rules={[{ required: true, message: '请输入手机号!' }]} >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="密码"
                                name="pwd"
                                rules={[{ required: true, message: '请输入密码!' }]} >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button
                                    type="primary"
                                    loading={this.state.loginLoading}
                                    htmlType="submit">登录</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    }

    onFinish(values){
        const _this = this;
        this.setState({
            loginLoading: true
        });
        BcmFetch.userLogin(values.phone, values.pwd).then(function (response) {
                  if (response.data.code === 0 && response.data.data.token) {
                      BcmData.setToken(response.data.data.token);
                      _this.props.history.push('/');
                  } else {
                      //console.log('result false ');
                      message.error(response.data.msg ? response.data.msg : '未知错误');
                  }
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  _this.setState({
                        loginLoading: false
                  });
              });
    };
    onFinishFailed(errorInfo){
        console.log('Failed:', errorInfo);
    };
}

export default BkLoginPage;