import BkBasePage from "../../base/BkBasePage";
import React from 'react';

class UCenterPage extends BkBasePage {

	render() {
		return <div>开发中...</div>
	}

}

export default UCenterPage;