import BkBasePage from "../../base/BkBasePage";
import React from 'react';
import {Tabs, Spin, Button, Modal} from 'antd';
import RecommendList from "./RecommendList";
import AddRecommendDialog from "./AddRecommendDialog";
const { TabPane } = Tabs;



class RecommendPage extends BkBasePage {

    constructor(props){
        super(props);
        this.state = {
        	recommend_panes : [
		      { title: '今日推荐', key: 'today' },
		      { title: '往期推荐', key: 'before' },],
	        manual_add_modal_show: false,
        }
    }

	componentDidMount() {
		super.componentDidMount();
	}

	render() {
		const isLoading = this.state.loading_status === 0;
    	const recommend_panes = this.state.recommend_panes;
    	//BcmLogger.log("render recommend_panes=" + recommend_panes);
		return <div>
					<Spin spinning={isLoading}>
						<Tabs defaultActiveKey="1" tabBarExtraContent={
							<Button onClick={()=>this.onClickAddManual()}>手动添加</Button>}>
							{recommend_panes.map(pane => (
								<TabPane tab={pane.title} key={pane.key}>
									<RecommendList tabKey={pane.key} />
								</TabPane>
					        ))}
						</Tabs>
					</Spin>
			        <Modal
						title="手动添加推荐"
						footer={null}
						destroyOnClose={true}
						visible={this.state.manual_add_modal_show}
						onCancel={()=> this.setState({manual_add_modal_show: false})} >
				            <AddRecommendDialog
					            mode={'add'}
					            item_recommend={{goods:{shop_type: 'B' ,item_id:'', quan_id: ''}}}
					            callback={(success)=> this.setState({manual_add_modal_show: false})}/>
					</Modal>
              </div>
	}

	onClickAddManual(){
    	this.setState({manual_add_modal_show: true})
	}

	renderList(data){

	}

    onClickRefresh(){
    	// this.setState({ loading_status: 0});
    }
}

export default RecommendPage;