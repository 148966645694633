import BkBasePage from "../../base/BkBasePage";
import React from 'react';
import {Button, message, Table, Spin, Modal, Popover} from 'antd';
import {BcmFetch} from "../../data/BcmFetch";
import BcmLogger from "../../base/BcmLogger";
import AddRecommendDialog from "./AddRecommendDialog";

class RecommendList extends BkBasePage {
	columns = [
	  {ellipsis: true, width: 12, title: '日期/排序', dataIndex: 'reco_time', key: 'reco_time',render: (text, record) => (
		        <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
		          <div>{record.recommend_time.split(' ')[0].replace("2020/", "")}</div>
		          <div>{record.recommend_time.split(' ')[1]}</div>
		        </div>
		      )},
	    {ellipsis: true, width: 16, title: '图标', dataIndex: 'Pic', key: 'Pic'
			,render: (text, record) => {
	            let img_url = '';
	            if( record.goods.images && record.goods.images.length > 0){
	                img_url = record.goods.images[0];
		        }
				return <Popover content={<img style={{width: '160px', height: '160px'}} src={img_url} alt="img"/>}
				         placement="left" trigger="hover">
					<img style={{width: '100px', height: '100px'}} src={img_url} alt="img" />
			    </Popover>},},
		{ellipsis: true, width: 10, title: '分类', dataIndex: 'goods.type_name', key: 'type_name', render: (text, record) => (
		          <div style={{fontSize:'14px', color:'black' }}>{record.type_name}</div>
		      )}
	  , {ellipsis: true, width: 50, title: '标题', dataIndex: 'goods.title', key: 'goods.title',render: (text, record) =>
				{ let title = record.title;
					if(!title || title.length <= 0){
						title = record.goods.title
					}
	  	        return <div style={{display:'flex', flexDirection:'column'}}>
		          <div style={{fontSize:'14px', color:'black' }}>{title}</div>
		          <div style={{fontSize:'14px', color:'grey'}}>{record.phrase}</div>
		        </div>}
		      }
	  , {ellipsis: true, width: 10, title: '价格', dataIndex: 'goods.price', key: 'Org_Price'
			,render: (text, record) => (
		        <div style={{display:'flex', flexDirection:'column'}}>
		          <div style={{fontSize:'18px', color:'red' }}>¥{record.goods.price_end}</div>
		          <div style={{textDecoration: 'line-through'}}>¥{record.goods.price_re}</div>
		        </div>
		      )}
	  , {ellipsis: true, width: 10, title: '券额', dataIndex: 'goods.quan', key: 'Quan_price', render: (text, record) => (
		          <div style={{fontSize:'16px', color:'green' }}>-¥{record.goods.quan}</div>
		      )}
	  , {ellipsis: true, width: 10, title: '佣金', dataIndex: 'goods.share_money', key: 'share_money', render: (text, record) => (
		          <div style={{fontSize:'14px', color:'blue' }}>¥{record.goods.share_money}</div>
		      )}
	  , {ellipsis: true, width: 10, title: '销量', dataIndex: 'goods.sales', key: 'Sales_num', render: (text, record) => (
		          <div>{record.goods.sale}</div>
		      )}
	  , {ellipsis: true, width: 20, title: '操作', dataIndex: 'opt', key: 'opt'
			,render:(text, record) => (
		        <span>
		          <a onClick={()=>this.onClickModify(record)}>修改</a>
		        </span>
	        )},
	];
    constructor(props){
        super(props);
        this.state = {
        	loading_status: 0,
	        pagination: {pageSize: 10, total: 1000, showSizeChanger:true},
	        recommend_data: [],
	        edit_modal_show: false,
	        item_recommend: null,
        }
    }

	componentDidMount() {
		super.componentDidMount();
		this.queryRecommendList();
	}

	render() {
		const isLoading = this.state.loading_status === 0;
    	const recommend_data = this.state.recommend_data;
		return <div>
					<div style={{display: 'flex', justifyContent: 'space-between', margin: '10px'}}>
						<div> </div>
						<Button onClick={() => this.onClickRefresh()}
						        loading={isLoading}>刷新</Button>
					</div>
			        <Spin spinning={isLoading}>
						<Table columns={this.columns}
						       dataSource={recommend_data}
						       pagination={this.state.pagination}
						       onChange={this.handleTableChange}/>
			        </Spin>
			        <Modal
						title="修改推荐"
						footer={null}
						destroyOnClose={true}
						visible={this.state.edit_modal_show}
						onCancel={()=> this.setState({edit_modal_show: false})} >
				            <AddRecommendDialog
					            mode={'edit'}
					            item_recommend={this.state.item_recommend}
					            callback={(success)=> this.setState({edit_modal_show: false})}/>
					</Modal>
              </div>
	}

	onClickModify(values){
    	this.setState({
		    item_recommend: values,
		    edit_modal_show: true,
	    })
	}

    onClickRefresh(){
		const pager = { ...this.state.pagination };
		pager.current = 1;
		this.setState({
			pagination: pager,
			loading_status: 0
		});
    	this.queryRecommendList();
    }

	handleTableChange = (pagination, filters, sorter) => {
    	//BcmLogger.log(" filters= " + JSON.stringify(filters));
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.setState({
			pagination: pager,
			loading_status: 0
		});
		this.queryRecommendList(pagination.current, pagination.pageSize, filters)
	};
	queryRecommendList(page=1, pageSize=20){
    	const _this = this;
    	let bcm_query = null;
    	if(this.props.tabKey === 'before'){
    		bcm_query = BcmFetch.frRecommendBefore(page, pageSize);
	    }else {
    		bcm_query = BcmFetch.frRecommendToday(page, pageSize);
	    }
		bcm_query.then((response) => {
			if(response.data.code === 0 && response.data.data){
				//BcmLogger.log('response.data.data :'+ response.data.data);
				_this.setState({
					loading_status: 1,
					recommend_data: response.data.data.map(item => {
						return {
							...item,
							key: item.id,
						}
					})
				});
				message.success(response.data.msg);
			}else {
				message.error("加载失败");
				_this.setState({loading_status: 2})
			}
		}).catch((error)=>{
			BcmLogger.error('queryRecommendList error :'+ error);
			message.error("加载错误");
			_this.setState({loading_status: -1})
		})
	}
}

export default RecommendList;