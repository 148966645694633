
const show_log = false;

class BcmLogger{

	static log(message){
		if(!show_log){
			return
		}
		console.log(message)
	}
	static error(message){
		if(!show_log){
			return
		}
		console.error(message)
	}

}

export default BcmLogger;