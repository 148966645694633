import React from 'react';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import './App.css';
import BkMainPage from "./main/BkMainPage";
import BkLoginPage from "./login/BkLoginPage";
import moment from 'moment';
import 'moment/locale/zh-cn';

function App() {
    moment.locale('zh-cn');
    return (
        <Router>
            <Route exact path="/login" component={BkLoginPage} />
            <Route exact path="/" component={BkMainPage} />
        </Router>
    );
}

export default App;
