import BkBasePage from "../../base/BkBasePage";
import React from 'react';
import {message, Table, Modal, Spin, Popover} from 'antd';
import {BcmFetch} from "../../data/BcmFetch";
import BcmLogger from "../../base/BcmLogger";
import AddRecommendDialog from "../recommend/AddRecommendDialog";


class MsHotList extends BkBasePage {

    constructor(props){
        super(props);
        this.state = {
            loading_status: 0,
        	rcm_list : [],
	        pagination: {pageSize: 20, showSizeChanger:true},
	        add_modal_show: false,
	        add_modal_date: null
        }
    }

	componentDidMount() {
		super.componentDidMount();
		this.onClickRefresh();
	}

	render() {
    	const rcm_list = this.state.rcm_list;
		const columns = [
	        {ellipsis: true, width: 20, title: '图标', dataIndex: 'Pic', key: 'Pic'
				,render: text =>
					<Popover content={<img style={{width: '160px', height: '160px'}} src={text} alt="img"/>}
					         placement="left" trigger="hover">
						<img style={{width: '100px', height: '100px'}} src={text} alt="img" />
				    </Popover>,}
		  , {ellipsis: true, width: 50, title: '标题', dataIndex: 'Title', key: 'Title'}
		  , {ellipsis: true, width: 10, title: '原价', dataIndex: 'Org_Price', key: 'Org_Price'
				,render: (text, record) => (
			        <div style={{display:'flex', flexDirection:'column'}}>
			          <div style={{fontSize:'18px', color:'red' }}>¥{record.Price}</div>
			          <div style={{textDecoration: 'line-through'}}>¥{record.Org_Price}</div>
			        </div>
			      )}
		  , {ellipsis: true, width: 10, title: '券额', dataIndex: 'Quan_price', key: 'Quan_price'}
		  , {ellipsis: true, width: 10, title: '佣金', dataIndex: 'share_money', key: 'share_money'}
		  , {ellipsis: true, width: 10, title: '销量', dataIndex: 'Sales_num', key: 'Sales_num'}
		  , {ellipsis: true, width: 20, title: '操作', dataIndex: 'opt', key: 'opt'
				,render:(text, record) => (
			        <span>
			          <a onClick={()=>this.clickItem(record)}>加入今日推荐</a>
			        </span>
		        )},
		];
    	return <div>
		        <Spin spinning={this.state.loading_status === 0}>
					<Table columns={columns}
					       dataSource={rcm_list}
					       pagination={this.state.pagination}
					       onChange={this.handleTableChange}/>
		        </Spin>
		        <Modal
					title="加入推荐"
					footer={null}
					destroyOnClose={true}
					visible={this.state.add_modal_show}
					onCancel={()=> this.setState({add_modal_show: false})} >
			            <AddRecommendDialog
				            item_recommend={{goods:this.state.add_modal_date}}
				            disable_edit_id={true}
				            callback={(success)=> this.setState({add_modal_show: false})}/>
				</Modal>
              </div>
	}

	clickItem(record){
    	if(!record || !record.GoodsID || !record.Quan_id){
    		message.warn("未知的商品");
		    return;
	    }
		this.setState({
			add_modal_show: true,
			add_modal_date: {
				item_id: record.GoodsID,
				quan_id: record.Quan_id
			}
		})
	}

    onClickRefresh(){
    	this.setState({ loading_status: 0});
    	this.queryLimitTimeList();
    }
	queryLimitTimeList(){
    	const time_id = this.props.time_id;
		BcmFetch.bkLimitTimeList(time_id, 1).then((response) => {
			if(response.data.code === 0 && response.data.data){
				this.setState({
					loading_status: 1,
					rcm_list: response.data.data.map(item => {
						return {
							...item,
							key: item.GoodsID,
						}
					})
				})
			}else {
				message.error("加载失败");
				this.setState({loading_status: 2})
			}
		}).catch((error)=>{
			BcmLogger.error('queryLimitTimeList error :'+ error);
			message.error("加载错误");
			this.setState({loading_status: -1})
		})
	}
}

export default MsHotList;