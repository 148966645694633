import React from 'react';
import {Avatar, Layout, Menu, Popover, Tabs} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
    LineChartOutlined,
    OrderedListOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import BkBasePage from "../base/BkBasePage";
import './BkHomePage.css'
import SummaryPage from "./summary/SummaryPage";
import ReportPage from "./report/ReportPage";
import UCenterPage from "./ucenter/UCenterPage";
import {BcmData} from "../data/BcmData";
import {BcmFetch} from "../data/BcmFetch";
import ExOrderPage from "./ex_order/ExOrderPage";
import MsHotsPage from "./ms_hots/MsHotsPage";
import RecommendPage from "./recommend/RecommendPage";
const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

const menu_list = {
    summary: '概览',
    report: '实时统计',
    exorder: '用户订单',
    recommends: '推荐管理',
    ms_hot: '买手热门',
    ucenter: '用户管理',
};
class BkHomePage extends BkBasePage {

    constructor(props){
        super(props);
        this.state = {
            login_status: 0,
            collapsed: false,
            menu_selected: 'summary',
            user_name: ''
        }
    }

    componentDidMount() {
        super.componentDidMount();
        if(!this.state.user_name){
            this.queryUserInfo();
        }
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
    	return <Layout style={{height: '100%'}}>
            <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                <div className="logo" >拔草猫</div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['summary']} onSelect={this.onMenuSelect.bind(this)}>
                    <Menu.Item key="summary">
                        <PieChartOutlined /> <span>{menu_list.summary}</span>
                    </Menu.Item>
                    <Menu.Item key="report">
                        <LineChartOutlined /> <span>{menu_list.report}</span>
                    </Menu.Item>
                    <Menu.Item key="exorder">
                        <OrderedListOutlined /> <span>{menu_list.exorder}</span>
                    </Menu.Item>
                    <Menu.Item key="recommends">
                        <OrderedListOutlined /> <span>{menu_list.recommends}</span>
                    </Menu.Item>
                    <Menu.Item key="ms_hot">
                        <OrderedListOutlined /> <span>{menu_list.ms_hot}</span>
                    </Menu.Item>
                    <Menu.Item key="ucenter">
                        <UserOutlined /> <span>{menu_list.ucenter}</span>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: 0 }}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                          className: 'trigger',
                          onClick: this.toggle,
                        })}
                      <div style={{fontSize: '16px'}}>{menu_list[this.state.menu_selected]}</div>
                      <div style={{display: 'flex', flex: 10}}> </div>
                      <div style={{margin: '0 20px',display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems: 'center'}}>
                          <Popover
                              content={
                                <div>
                                  <div> {this.state.user_name} </div>
                                  <a href="" onClick={()=>this.onClickLogout()}>退出登录</a>
                                </div>
                              }>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems: 'center'}}>
                                  <Avatar style={{color: '#f56a00', backgroundColor: '#fde3cf'}}>{this.state.user_name}</Avatar>
                              </div>
                          </Popover>
                      </div>
                  </div>
              </Header>
              <Content
                    className="site-layout-background"
                    style={{margin: '24px 16px', padding: 24, minHeight: 300,}}>
                    <Tabs defaultActiveKey="1" activeKey={this.state.menu_selected} tabPosition="left" renderTabBar={this.renderTab}>
                        <TabPane tab={menu_list.summary} key="summary">
                            <SummaryPage />
                        </TabPane>
                        <TabPane tab={menu_list.report}  key="report">
                            <ReportPage />
                        </TabPane>
                        <TabPane tab={menu_list.exorder} key="exorder">
                            <ExOrderPage />
                        </TabPane>
                        <TabPane tab={menu_list.recommends} key="recommends">
                            <RecommendPage />
                        </TabPane>
                        <TabPane tab={menu_list.ms_hot} key="ms_hot">
                            <MsHotsPage />
                        </TabPane>
                        <TabPane tab={menu_list.ucenter} key="ucenter">
                            <UCenterPage />
                        </TabPane>
                    </Tabs>
              </Content>
            </Layout>
      </Layout>
    }

    renderTab(prop, DefaultTabBar){
        return <div style={{display:'none'}} />
    }

    onMenuSelect(item, key, keyPath, selectedKeys, domEvent){
        this.setState( { menu_selected: item.key });
    }

    onClickLogout(){
        BcmData.setToken('')
    }

    queryUserInfo(){
        BcmFetch.userInfo().then((response) => {
            if(response.data.code === 0){
                this.setState({user_name: response.data.data.nick})
            }
        })
    }
}

export default BkHomePage;