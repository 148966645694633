import BkBasePage from "../../base/BkBasePage";
import React from 'react';
import {Button, message, Table, Popover, DatePicker, Spin} from "antd";
import BcmLogger from "../../base/BcmLogger";
import {BcmFetch} from "../../data/BcmFetch";
import moment from 'moment';
import 'moment/locale/zh-cn';
const { MonthPicker} = DatePicker;
const querystring = require('querystring');

class ExOrderPage extends BkBasePage {

    constructor(props){
        super(props);
        this.state = {
            loading_status: 0,
            order_data: [{key:1, order_time:'loading', pic: '',
	            title:'loading', mall: 'loading', status_text: 'loading', price_text: 'price_text', reward_money: 'loading'}],
	        pagination: {pageSize: 10, total: 1000, showSizeChanger:true},
	        filteredInfo: {},
	        sync_loading_status: 1,
        };
        moment.locale('zh-cn');
    }

	componentDidMount() {
		super.componentDidMount();
		this.queryExOrder();
	}

	render() {
		const columns = [
		    {ellipsis: false, width: 8, title: '订单时间', dataIndex: 'order_time', key: 'order_time'}
		  , {ellipsis: true, width: 6, title: '图标', dataIndex: 'pic', key: 'pic'
				,render: text =>
					<Popover content={<img style={{width: '160px', height: '160px'}} src={text} alt="img"/>}
					         placement="left" trigger="hover">
						<img style={{width: '60px', height: '60px'}} src={text} alt="img" />
				    </Popover>,}
		  , {ellipsis: true, width: 20, title: '标题', dataIndex: 'title', key: 'title'}
		  , {ellipsis: true, width: 6, title: '商城', dataIndex: 'mall', key: 'mall'}
		  , {ellipsis: true, width: 6, title: '状态', dataIndex: 'status_text', key: 'status_text'
				,render: text =>{
		  	        return this.orderStatusDiv(text)
				},}
		  , {ellipsis: true, width: 8, title: '金额', dataIndex: 'price_text', key: 'price_text'}
		  , {ellipsis: true, width: 8, title: '佣金', dataIndex: 'reward_money', key: 'reward_money'}
		  , {ellipsis: true, width: 8, title: '剁手', dataIndex: 'reward_money', key: 'reward_money',
				render: (text, record) =>{
					return <div>{record.user ? record.user.nick : ''}</div>
				}}];
		const isLoading = this.state.loading_status === 0;
		return <div>
					<div style={{display: 'flex', justifyContent: 'space-between', margin: '10px'}}>
						<div>
							<Spin size={"small"} spinning={this.state.sync_loading_status === 0}>
							<MonthPicker
								// defaultValue={moment('2020-01', 'YYYY-MM')}
								onChange={(date, dateString)=>this.onSyncDateChange(date, dateString)} placeholder="同步订单"/>
							</Spin>
						</div>
						<div> </div>
						<Button onClick={() => this.onClickRefresh()}
						        loading={isLoading}>刷新</Button>
					</div>
					<Table columns={columns}
		              pagination={this.state.pagination}
		              dataSource={this.state.live_data}
		              loading={isLoading}
		              onChange={this.handleTableChange}/>
              </div>
	}

	onSyncDateChange(date, dateString){
    	if(!date){
    		return
	    }
		const year = date.year();
		const month = date.month() + 1;
		BcmLogger.log(" year=" + year + "  month=" + month);
		this.syncExOrder(year, month);
	}


	handleTableChange = (pagination, filters, sorter) => {
    	//BcmLogger.log(" filters= " + JSON.stringify(filters));
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.setState({
			pagination: pager,
			loading_status: 0
		});
		this.queryExOrder(pagination.current, pagination.pageSize, filters)
	};

    orderStatusDiv(status_text){
    	const color_arr = {
    		'订单付款': "blue",
		    '订单失效': "grey",
		    '订单结算': "green",
	    };
    	let text_color = "grey";
    	if(color_arr.hasOwnProperty(status_text)){
    		text_color = color_arr[status_text]
	    }
    	let textStyle = {color:text_color};
    	if(status_text.indexOf('失') >= 0){
    		textStyle = {color:text_color, textDecoration: 'line-through'}
	    }
    	return <div style={textStyle}>{status_text}</div>
    }


    onClickRefresh(){
    	//BcmLogger.log(" onClickRefresh  ...");
    	this.setState({ loading_status: 0});
    	this.queryExOrder(1, this.state.pagination.pageSize);
    }
	queryExOrder(page, pageSize, filters){
		BcmFetch.bkExOrderList(page, pageSize,filters).then((response) => {
			if(response.data.code === 0 && response.data.data){
				this.setState({
					loading_status: 1,
					live_data: response.data.data.map(item => {
						let order_time = item.order_time.replace('2020/', '');
						return {
							...item,
							key: item.id,
							order_time: order_time,
							reward_money: item.reward_money.toFixed(2)
						}
					})
				})
			}else {
				message.error("加载失败");
				this.setState({loading_status: 2})
			}
		}).catch((error)=>{
			//BcmLogger.error('queryExOrder error :'+ error);
			message.error("加载错误");
			this.setState({loading_status: -1})
		})
	}

	syncExOrder(year, month){
		this.setState({
			sync_loading_status: 0,
		});
		BcmFetch.bkExOrderSync(year, month).then((response) => {
			if(response.data.code === 0 && response.data.data){
				this.setState({
					sync_loading_status: 1,
				});
				message.success("同步完成,同步了" + response.data.data.sync_count + "个订单")
			}else {
				message.error("同步失败");
				this.setState({sync_loading_status: 2})
			}
		}).catch((error)=>{
			BcmLogger.error('syncExOrder error :'+ error);
			message.error("同步错误");
			this.setState({sync_loading_status: -1})
		});
	}
}

export default ExOrderPage;