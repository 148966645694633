import BkBasePage from "../../base/BkBasePage";
import React from 'react';
import {Card, Statistic, Divider, Row, Col, message, Spin, Button} from 'antd';
import './SummaryPage.css'
import {BcmFetch} from "../../data/BcmFetch";
import BcmLogger from "../../base/BcmLogger";

class SummaryPage extends BkBasePage {

	constructor(props) {
		BcmLogger.log(" summary_data constructor " );
		super(props);
		this.state = {
			loading_status: 0,
			summary_data: null,
		};
	}

	componentDidMount() {
		super.componentDidMount();
		BcmLogger.log(" summary_data = " + this.state.summary_data);
		this.querySummary();
	}

	render(){
		const data = this.state.summary_data;
		const isLoading = this.state.loading_status === 0;
		return <div>
			<Spin spinning={isLoading} >
				<div style={{display: 'flex', justifyContent: 'space-between', margin: '10px'}}>
					<div> </div>
					<Button onClick={() => this.onClickRefresh()} loading={isLoading}>刷新</Button>
				</div>
				<Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>概览</Divider>
				<div style={{margin: '20px'}}>今日与昨日对比：</div>
			    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("今日访问", data? data.today.view:0, "blue", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("今日访问人数", data? data.today.view_u:0, "blue", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("今日搜索", data? data.today.search:0, "blue", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("今日搜索人数", data? data.today.search_u:0, "blue", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("今日订单数", data? data.today.order:0, "blue", "")}
					</Col>
			    </Row>
				<div style={{ height: '8px' }}/>
			    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("昨日访问", data? data.yesterday.view:0, "black", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("昨日访问人数", data? data.yesterday.view_u:0, "black", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("昨日搜索", data? data.yesterday.search:0, "black", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("昨日搜索人数", data? data.yesterday.search_u:0, "black", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("昨日订单数", data? data.yesterday.order:0, "blue", "")}
					</Col>
			    </Row>
				<div style={{margin: '20px'}}>本月与上月对比：</div>
			    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("本月访问", data? data.this_month.view:0, "blue", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("本月访问人数", data? data.this_month.view_u:0, "blue", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("本月搜索", data? data.this_month.search:0, "blue", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("本月搜索人数", data? data.this_month.search_u:0, "blue", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("本月订单数", data? data.this_month.order:0, "blue", "")}
					</Col>
			    </Row>
				<div style={{ height: '8px' }}/>
			    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("上月访问", data? data.last_month.view:0, "black", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("上月访问人数", data? data.last_month.view_u:0, "black", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("上月搜索", data? data.last_month.search:0, "black", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("上月搜索人数", data? data.last_month.search_u:0, "black", "")}
					</Col>
					<Col className="gutter-row" span={4}>
						{this.cardStatisticItem("上月订单数", data? data.last_month.order:0, "blue", "")}
					</Col>
			    </Row>
			</Spin>
		</div>
	}

	cardStatisticItem(name, value, color, suffix){
		return<Card>
			<Statistic title={name} value={value}
	            valueStyle={{ color: color }}
	            precision={0} suffix={suffix} />
		</Card>
	}

	onClickRefresh(){
		this.setState({loading_status: 0});
		this.querySummary();
	}

	querySummary(){
		BcmFetch.bkSummary().then((response) => {
			if(response.data.code === 0){
				this.setState({
					loading_status: 1,
					summary_data: response.data.data
				})
			}else {
				this.setState({loading_status: 2});
				message.error("加载失败");
			}
		}).catch((error)=>{
			BcmLogger.error('querySummary error :'+ error);
			this.setState({loading_status: -1});
			message.error("加载错误");
		})
	}

}

export default SummaryPage;