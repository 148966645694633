import BkBasePage from "../../base/BkBasePage";
import React from 'react';
import {Button, message, Table} from 'antd';
import {BcmFetch} from "../../data/BcmFetch";
import BcmLogger from "../../base/BcmLogger";
const querystring = require('querystring');


class ReportPage extends BkBasePage {

    constructor(props){
        super(props);
        this.state = {
            loading_status: 0,
            live_data: [{key:1, create_time:'loading', extra:'loading', ip: 'loading', uuid: 'loading'}],
	        pagination: {pageSize: 10, total: 1000, showSizeChanger:true},
	        filteredInfo: {}
        }
    }

	componentDidMount() {
		super.componentDidMount();
		this.queryAnLive();
	}

	render() {
		const columns = [
		    {ellipsis: true, width: 20, title: ' 时间 ', dataIndex: 'create_time', key: 'create_time'}
		  , {ellipsis: true, width: 10, title: '类型', dataIndex: 'type', key: 'type', filters: [
		          { text: '访问', value: '1' },
		          { text: '点击', value: '2' },
		        ],}
		  , {ellipsis: true, width: 20, title: '名称', dataIndex: 'name', key: 'name', filters: [
		          { text: '搜索', value: 'search' },
		          { text: '页面', value: '/' },
		        ],}
		  , {ellipsis: true, width: 50, title: '消息', dataIndex: 'extra', key: 'extra'}
		  , {ellipsis: true, width: 20, title: ' 地址 ', dataIndex: 'ip', key: 'ip'}
		  , {ellipsis: true, width: 20, title: ' 用户 ', dataIndex: 'uuid', key: 'uuid'}];
		const isLoading = this.state.loading_status === 0;
		return <div>
					<div style={{display: 'flex', justifyContent: 'space-between', margin: '10px'}}>
						<div> </div>
						<Button onClick={() => this.onClickRefresh()}
						        loading={isLoading}>刷新</Button>
					</div>
					<Table columns={columns}
		              pagination={this.state.pagination}
		              dataSource={this.state.live_data}
		              loading={isLoading}
		              onChange={this.handleTableChange}/>
              </div>
	}


	handleTableChange = (pagination, filters, sorter) => {
    	BcmLogger.log(" filters= " + JSON.stringify(filters));
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.setState({
			pagination: pager,
			loading_status: 0
		});
		// this.fetch({
		// 	results: pagination.pageSize,
		// 	page: pagination.current,
		// 	sortField: sorter.field,
		// 	sortOrder: sorter.order,
		// 	...filters,
		// });
		this.queryAnLive(pagination.current, pagination.pageSize, filters)
	};


    onClickRefresh(){
    	this.setState({ loading_status: 0});
    	this.queryAnLive(1, this.state.pagination.pageSize);
    }
	queryAnLive(page, pageSize, filters){
		BcmFetch.bkAnLive(page, pageSize,filters).then((response) => {
			if(response.data.code === 0 && response.data.data){
				this.setState({
					loading_status: 1,
					live_data: response.data.data.map(item => {
						let create_time = item.create_time.replace('2020/', '');
						let name = item.name;
						let extra = decodeURIComponent(item.extra);
						if(item.type === 2){
							if(item.name === '搜索' ) {
								if(item.extra.indexOf("=") > 0) {
									extra = '商城:' + querystring.parse(item.extra).mall + ', 关键词:' + querystring.parse(item.extra).q
								}
							}
						}
						if(extra){
							BcmLogger.log(" item.extra= " + item.extra);
							BcmLogger.log(" extra= " + extra);
							extra = querystring.decode(extra);
							if(extra.q){
								let extra_str = "";
								BcmLogger.log(" JSON.stringify(extra) = " + JSON.stringify(extra));
								if(extra.mall){
									extra_str = extra_str + "商城：" + extra.mall + ", ";
								}
								extra_str = extra_str + "关键字：" + extra.q;
								extra = extra_str;
							}else {
								extra = JSON.stringify(extra);
							}
						}
						return {
							...item,
							key: item.id,
							create_time: create_time,
							type: item.type,
							name: name,
							extra: extra
						}
					})
				})
			}else {
				message.error("加载失败");
				this.setState({loading_status: 2})
			}
		}).catch((error)=>{
			BcmLogger.error('queryAnLive error :'+ error);
			message.error("加载错误");
			this.setState({loading_status: -1})
		})
	}
}

export default ReportPage;